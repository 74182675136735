import { Utils } from './../../utils';
import { AppService } from "../../app.service";
import { AppExService } from "../../app-ex.service";
import { Component, OnInit, ViewEncapsulation, Input, HostListener, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { InitialComponent } from "../../initial/initial.component";
import { ModalState } from "../shared.model";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { assignedPermsSideBar } from "./sidebar.model";

import { getLanguage } from './../../languages';
import { LanguageInterface } from './../../languages/interface.language';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidebarElement') sidebarElement!: ElementRef;
  @Input() myInput: boolean;
  @Output() myInputChange: EventEmitter<any> = new EventEmitter();
  modal: ModalState;
  openCalendarMsg = false;
  revert = false;
  publicAccordian = true;
  pageAccordian = true;
  active = false;
  citiesAccordian = true;
  pageData = { name: "", count: "" };
  openCalenderSubs: Subscription;
  selectedMenu: string;
  checkBoxValue: boolean;
  headerSub: Subscription;
  showTaskPoup = false;
  addTicketPopUp = false;
  contactData: Array<any> = [];
  allAssociatedContacts: Array<any> = [];
  taskMode: string;
  showTaskPoupSubs: Subscription;
  editStatusSub: Subscription;
  countSubs: Subscription;
  sidebarSubscription: Subscription;
  openMailSubs: Subscription;
  openNotesSubs: Subscription;
  toggleView = true;
  countData: any = {};
  countExist = false;
  selectedTab: any;
  navigation = "icon-menu";
  urlValue: any;
  iconView = false;
  openMail = false;
  openNotes = false;
  sidebar = {
    contact: false,
    property: false,
    transaction: false,
    checklist: false,
    user: false,
    security: false,
    tasks: false,
    message: false,
    support: false,
    websitemanagement: false,
    hrms: false,
    dashboard: false,
    globalSettings: false,
    subscription: false,
    dashboardCalender: false,
    document: false,
    dmsTags: false,
  };
  dataSubscription: Subscription;
  checkboxValueSubscription: Subscription;
  groupName = '';
  publicData = {
    "company_name": 'Opta Planner',"company_title": 'Sign In To Admin',
    "company_logo": 'assets/images/Web-Logo@2x.png',"company_fav_icon": 'assets/icon/favicon.png'};
  toggleImageView = false;
  permsBar = assignedPermsSideBar;
  loggedData = { is_superuser: false, is_admin: false, role:null };
  @Input() navState: boolean;
  @Input() totalCount;
  langSubs: Subscription;
  pageStrings: LanguageInterface;
  isMobile:Boolean = true;
  accordionindex: number = 2;
  constructor(
    public utils: Utils,
    public appService: AppService,
    public route: ActivatedRoute,
    public initialComponent: InitialComponent,
    readonly appExService: AppExService,
    public router: Router,
  ) {
    this.modal = new ModalState();
    this.isMobile=window.innerWidth <= 880;
  }

  ngOnInit() {
    this.onImageLoad()
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if(data.lang){
        this.pageStrings = getLanguage(data.lang);
      }
    });
    const obj = localStorage.getItem('optplanner_user');
    this.groupName = obj ? JSON.parse(obj).group_name : '';
    this.loggedData = obj && {
      is_superuser: JSON.parse(obj).is_superuser,
      is_admin: JSON.parse(obj).is_admin,
      role: JSON.parse(obj).userType.type
    }
    this.dataSubscription = this.appService.getUpdatedData$.subscribe(
      (data) => {
        if (data) {
          this.initialComponent.getCount();
        }
      }
    );
    this.countSubs = this.appService.countSource.subscribe((count: any) => {
      if (count.task_count || count.contact_count) {
        this.countExist = true;
        this.countData = count;
        if (!this.router.url.includes("/property")) {
          this.getContacts(this.countData.contact_count);
        }
      }
    });
    this.headerSub = this.appService.headerSource$.subscribe((page) => {
      this.pageData["name"] = page.name;
      this.pageData["count"] = page.count;
    });
    this.router.events.subscribe((routeObj) => {
      if (routeObj instanceof NavigationEnd) {
        this.modal = new ModalState();
        this.appService.updateModalState(false);
      }
    });
    this.sidebarSubscription = this.appService.sidebarSource$.subscribe((state) => {
        this.toggleView = false;
        this.updateView('onInit');
      }
    );
  }
  onImageLoad() {
    this.toggleImageView = true;
  }
  navigateView(caller){
    if(caller === 'solver' || caller === 'individualgroup-solver' || caller === 'bcba-solver' 
     || caller === 'ot-solver'  || caller === 'speech-solver'){
      this.accordionindex = 1;
    }else{
      this.accordionindex = 2;
    }
    if(caller === 'solver' && this.router.url === "/solver/all-solver" && this.isMobile){
      this.myInput = true; 
    }
    else{
      this.myInput = false;
      this.myInputChange.emit(!this.toggleView);
    }

    // this.myInput = false; 
    // this.myInputChange.emit(!this.toggleView);
    
   
    
    switch (caller) {
      case 'dashboard':
        this.router.navigate(['/dashboard']);
        break;
      case 'clinics':        
      this.router.navigate(['/clinics']);
      break;
      case 'skills':
        this.router.navigate(['/skills']);
        break;
      case 'planner':
        this.router.navigate(['/planner']);
        break;
      case 'provider':
        this.router.navigate(['/provider']);
        break;
      case 'patient':
        this.router.navigate(['/patient']);
        break;
      case 'scheduling':
        this.router.navigate(['/scheduling']);
        break;
      case 'availability':
        this.router.navigate(['/availability']);
        break;
      case 'spots':
        this.router.navigate(['/spots']);
        break;
      case 'solver':
        this.router.navigate(['/solver']);
        break;   
      case 'individualgroup-solver':
        this.router.navigate(['/solver/individualgroup-solver']);
        break;   
      case 'bcba-solver':
        this.router.navigate(['/solver/bcba-solver']);
        break;   
      case 'ot-solver':
        this.router.navigate(['/solver/ot-solver']);
        break;   
      case 'speech-solver':
        this.router.navigate(['/solver/speech-solver']);
        break;   
      case 'contracts':
        this.router.navigate(['/contracts']);
        break;
      case 'patient-team':
        this.router.navigate(['/patient-team']);
        break; 
      case 'therapygroup':
        this.router.navigate(['/therapygroup']);
        break;  
      case 'holidays':
        this.router.navigate(['/holidays']);
        break; 
      case 'setting':
      this.router.navigate(['/setting']);
      break;      
      case 'job-config':
        this.router.navigate(['/job-config']);
      break; 
      case 'trash':
        this.router.navigate(['/trash']);
      break;  
      default:
        this.router.navigate(['/dashboard']);
        break;
    }
  }


  updateView(eventType) {
    if(this.isMobile){
      this.toggleView = true;
      this.myInputChange.emit(!this.toggleView);
    }else{
      this.toggleView = !this.toggleView;
    }
    this.appService.updateView(this.toggleView);
    if(this.router.url.includes("/solver-plan-details") && eventType === 'sidebarButton'){
      this.appExService.UpdateCalendarToRender(true);
    }
    if(this.router.url.includes("/all-scheduling") && eventType === 'sidebarButton'){
      this.appExService.UpdateCalendarToRender(true);
    }
    if(this.router.url.includes("/all-availability") && eventType === 'sidebarButton'){      
      this.appExService.UpdateCalendarToRender(true);
    }
    if(this.router.url.includes("/patient-details") && eventType === 'sidebarButton'){      
      this.appExService.UpdateCalendarToRender(true);
    }
    if(this.router.url.includes("/provider-details") && eventType === 'sidebarButton'){      
      this.appExService.UpdateCalendarToRender(true);
    }
  }

  /**
   * To open add new modal based on selected page
   */
  openAddNewModal() {
    this.modal.addContact = true;
    this.appService.openNoteSidebarFooterPopup(false);
    this.appService.openMailSidebarPopup(false);
    this.appService.openAddTaskSidebarPopup("CLOSE");
  }

  /**
   *  To update Modal state
   * @param key Selected modal key
   */
  updateModalState(event) {
    this.modal.addContact = false;
    this.addTicketPopUp = event;
  }


  hideVisible() {
    this.active = !this.active;
  }

  sendMailPopUp() {
    this.appService.openMailSidebarPopup(true);
    this.appService.openAddTaskSidebarPopup("CLOSE");
    this.modal.addContact = false;
    this.appService.openNoteSidebarFooterPopup(false);
  }
  openAddNote() {
    this.appService.openNoteSidebarFooterPopup(true);
    this.appService.openMailSidebarPopup(false);
    this.appService.openAddTaskSidebarPopup("CLOSE");
    this.modal.addContact = false;
  }

  ngOnDestroy(): void {
    if (this.openMail) {
      this.openMailSubs.unsubscribe();
    }
    if (this.openNotesSubs) {
      this.openNotesSubs.unsubscribe();
    }
    this.dataSubscription && this.dataSubscription.unsubscribe();
    this.langSubs && this.langSubs.unsubscribe();
  }

  openAddPopUp() {
    this.appService.openAddTaskSidebarPopup("SAVE");
    this.modal.addContact = false;
    this.appService.openMailSidebarPopup(false);
    this.appService.openNoteSidebarFooterPopup(false);
  }

  /**
   * To get contact list
   * @param count Contact count
   */
  getContacts(count) {
  }
  sendScheduleMetting() {
    this.appService.openCalendarPopup(true);
  }
  pageOpen() {
    this.router.navigate(['/website-management/pages']);
  }
  pageCommunity() {
    this.router.navigate(['/website-management/community']);
  }

  sendTextMessage() {
    this.appService.openMessagePopUp(true);
  }

  sendCallMessage() {
    const data = {
      state: true,
    };
    this.appService.openCallPopUp(data);
  }

  addTicket() {
    this.addTicketPopUp = true;
  }
  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    this.active = false;
  }
  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  onWrapperClick(event: MouseEvent){
    const clickedInside = this.sidebarElement.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.myInputChange.emit(false);
    } 
  }
}
